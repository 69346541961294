* {
  box-sizing: border-box;
  background-color: #0E4C75;
  font-family: inter;
}


input[type=text],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  color: #ffffff;
}

label {
  display: inline-block;
}

.inputs #submit {
  background: linear-gradient(90deg, #5870FD 0%, #F167C9 100%);
  color: white;
  padding: 8px 150px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  float: right;
  text-decoration: none;
  font-weight: bold;
  font-family: inter;
}

.inputs #submit:hover {
  background-color: #870300;
}

.container {
  border-radius: 5px;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.col-100 {
  float: left;
  width: 100%;
  margin-top: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.offer-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 6px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.regenerate {
  color: #E20300;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 14px;
  display: none;
  background: #0E4C75 !important;
}

.checkboxitem {
  font-size: 14px;
}

.text {
  display: block;
}

.logo-container {
  height: 100px;
  width: 100%;
}


.logo {
  /* background-image: url("../public/assets/Images/vodaLogo.png"); */
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

.description {
  height: 30px;
  width: 100%;
  background: linear-gradient(90deg, #5870FD 0%, #F167C9 100%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service {
  margin-top: 30px;
  height: 300px;
}

/* Responsive layout - when the screen is less than 600px wide, 
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  .col-25,
  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 0;
  }

  .description {
    height: 40px;
  }

  label {
    font-size: 20px;
    margin: 10px auto;
  }

  input[type=text] {
    font-size: 14px;
    height: 50px;
  }

  .checkboxitem {
    font-size: 16px;
  }

  .offer-group {
    display: block;
    flex-basis: 100%;
  }

  .inputs #submit {
    font-size: 18px;
    font-family: "inter";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    margin-top: 35px;
    text-decoration: none;
    height: 50px;
  }

  .inputs #regenerate {
    font-size: 16px;
  }
}

input#msisdnInput {
  background: transparent;
}

input#msisdnInput::placeholder {
  color: whitesmoke;
}

/* 
input#msisdnInput:focus {
  background-color: transparent;
} */

input#msisdnInput:focus {
  background-color: transparent;
  border-color: white;
}

input:focus {
  border-color: #007BFF;
  /* Change to the color you want on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Optional: adds a shadow for better focus indication */
}

input:-internal-autofill-selected {
  background-color: transparent;
}
.constainer {
    font-size: medium;
    color: #ffffff;
}

.input {
    border: none;
    border-color: initial;
    color: rgba(33, 33, 217, 0.845)
}

.musicGroupVector {
    width: 100%;
}

.disabled_anchor {
    color: rgb(239, 232, 232);
}
